.button {
    @apply inline-flex px-6 lg:px-8 py-2 rounded-lg border-2 font-heading no-underline antialiased transition-colors duration-500 leading-none text-3xl;
}

.page-btn-primary {
    @apply button bg-primary border-primary text-white hover:bg-primary-light;
    box-shadow: 3px 4px 0 #234148;
}

.page-btn-primary-outlined {
    @apply button text-primary-dark border-primary-dark hover:bg-primary hover:text-white;
    box-shadow: 3px 4px 0 #234148;
}

.page-btn-secondary {
  @apply button bg-secondary border-secondary text-white hover:bg-secondary-light;
  box-shadow: 3px 4px 0 #234148;
}

.page-btn-secondary-outlined {
  @apply button text-secondary-dark border-secondary hover:bg-secondary hover:text-white;
  box-shadow: 3px 4px 0 #234148;
}

.page-btn-accent {
  @apply button bg-accent border-accent text-white hover:bg-accent-light;
  box-shadow: 3px 4px 0 #234148;
}

.page-btn-accent-outlined {
  @apply button text-accent-dark border-accent hover:bg-accent hover:text-white;
  box-shadow: 3px 4px 0 #234148;
}

