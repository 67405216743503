.page-h1 {
  @apply text-[38px] md:text-6xl leading-normal font-heading tracking-wide;
}

.page-h2 {
  @apply font-heading text-accent-dark text-4xl md:text-5xl mt-4 mb-6;
}

.page-h3 {
  @apply font-heading text-primary-dark text-2xl leading-6 inline-block mb-2
}

.block-h2 {
  @apply text-xl md:text-3xl font-bold mb-2;
}

.block-h3 {
  @apply text-lg md:text-2xl font-bold pt-4;
}

.footer-contact {
  @apply text-3xl md:text-[28px] text-shadow-sm tracking-wide font-heading text-white;
}

.footer-contact-label {
  @apply font-sans text-white mb-2 mt-10 uppercase font-bold tracking-wider after:bg-primary-dark/40 after:h-[1.5px] after:mt-1 after:w-10 after:block after:mx-auto after:lg:ml-0  text-sm;
}

.text-shadow {
  text-shadow: 1.5px 1.5px 0 rgba(35,65,72,1);
}

.text-shadow-sm {
  text-shadow: 1px 1px 0px rgba(0,0,0,.35);
}

.text-shadow-md {
  text-shadow: 1.5px 1.5px 0px rgba(0,0,0,1);
}

.text-shadow-lg {
  text-shadow: 3px 3px 0px rgba(0,0,0,.8);
}

.svg-shadow {
  filter: drop-shadow(2px 2px 0 rgba(35,65,72,1));
}

.text-shadow-y {
  text-shadow: 0px 2px 0px rgba(0,0,0,.2);
}
