/********
 * Front-end CSS file
 ********/

@import "components/fonts.css";


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: white;
}

a, p, h1, h2, h3, h4, strong, li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.pin {
  @apply top-0 left-0 right-0 bottom-0
}

.bg-cover-x {
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
}

@media (min-width: 1280px) {
  .second-level-nav-item .second-level-nav-dd { display: none; }
  .second-level-nav-item:hover .second-level-nav-dd,
  .second-level-nav-item:focus .second-level-nav-dd,
  .second-level-nav-item:focus-within .second-level-nav-dd {
    display: flex;
  }
}

@import "components/typography.css";
@import "components/buttons.css";
